import styled from 'styled-components';

const Page = styled.div`
	padding-top: 80px;
	padding-bottom: 100px;
	
	@media (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 60px;
	}
`;

export default Page;