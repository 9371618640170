import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import SVG from 'react-svg';

const ReadMore = ({ to, text, reverse, as, target, href }) => (
	<Link
		to={to}
		href={href}
		as={as}
		className={'read-more'}
		reverse={reverse}
		target={target}
	>
		<span>{ text }</span>
		<SVG
			src={'/icons/chevron-right.svg'}
		/>
	</Link>
);

const Link = styled(GatsbyLink)`
	display: flex;
	align-items: center;
	flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
	font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
	text-decoration: none;
	color: ${({ theme }) => theme.color.blueAlt};
	
	svg {
		width: 6px;
		height: 11px;
		margin-left: ${({ reverse }) => reverse ? '0' : '6px'};
		margin-right: ${({ reverse }) => reverse ? '6px' : '0'};
		transform: ${({ reverse }) => reverse ? 'rotate(180deg)' : 'none'};
		opacity: .8;
	}
	
	path {
		fill: ${({ theme, reverse }) => reverse ? theme.color.text : theme.color.blueLight};
	}
`;

ReadMore.defaultProps = {
	text: 'Read more',
	as: Link,
	target: '_self'
}

export default ReadMore;