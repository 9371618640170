import React, { Fragment } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

import Container from '../components/Container';
import FadeEntry from '../components/FadeEntry';
import MetaData from '../components/MetaData';
import Page from '../components/Page';
import ReadMore from '../components/ReadMore';
import Title from '../components/Title';

const Post = ({ pageContext: { acf, title, categories, content, author, date } }) => {
	return (
		<Outer>
			<MetaData
				title={acf && acf.seo_title ? acf.seo_title : title}
				description={acf && acf.seo_description ? acf.seo_description : null}
				images={acf && acf.seo_images ? acf.seo_images : null}
			/>

			<Page>
				<Article>
					<Container
						size={'narrow'}
					>
						<FadeEntry
							className={'article__header'}
						>
							<div className="article__header__left">
								<ReadMore
									to={'/news'}
									text={'all news'}
									reverse
								/>
							</div>

							<div className="article__header__right">
								<time>{ format(date, 'DD MMMM YYYY') }</time>
								{ categories && !!categories.length ? (
									<Fragment>
										<p className="article__header__category-text">{ categories[0].name }</p>

										{ categories[0].acf.icon ? (
											<div className="article__header__category-img">
												<img
													src={categories[0].acf.icon.sizes.large.source_url}
													alt={ categories[0].name }
												/>
											</div>
										) : null }
									</Fragment>
								) : null }
							</div>
						</FadeEntry>

						{ acf.post_image ? (
							<FadeEntry
								className={'article__image'}
							>
								<img src={acf.post_image.source_url} alt={acf.post_image.alt_text} />
							</FadeEntry>
						) : null }

						<FadeEntry
							className={'article__title'}
						>
							<Title
								size={'titleLg'}
								dangerouslySetInnerHTML={{ __html: title }}
							/>
						</FadeEntry>

						<FadeEntry
							delay={100}
						>
							<div
								dangerouslySetInnerHTML={{ __html: content }}
								className={'user-content'}
							/>

							{/*{ author ? (*/}
								{/*<p className="article__author">*/}
									{/*<strong>{ author.name }</strong>*/}
								{/*</p>*/}
							{/*) : null }*/}
						</FadeEntry>
					</Container>
				</Article>
			</Page>
		</Outer>
	);
}

const Article = styled.article`
	.article__header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		
		time {
			text-align: right;
			font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
		}
		
		&__right {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: flex-end;
			flex-wrap: wrap;
			width: 180px;
			height: 50px;
		}
		
		&__category-text {
			margin-top: 8px;
			text-align: right;
			text-transform: uppercase;
			font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
			font-size: ${({ theme }) => theme.fontSize.bodySm};
			color: ${({ theme }) => theme.color.blueAlt};
		}
		
		&__category-img {
			display: flex;
			flex-basis: 100%;
			margin: 0 0 0 14px;
			
			img {
				position: relative;
				top: -4px;
				height: 48px;
			}
		}
		
		+* {
			margin-top: 30px;
		}
	}
	
	.article__image {
		img {
			width: 100%;
			margin: 0 auto;
		}
		
		+* {
			margin-top: 45px;
		}
	}
	
	.article__title {
		width: 100%;
		max-width: 784px;
		
		+* {
			margin-top: 40px;
		}
	}
	
	.article__author {
		margin-top: 30px;
		font-size: ${({ theme }) => theme.fontSize.bodyLg};
		opacity: .8;
	}
	
	.user-content {
		font-size: ${({ theme }) => theme.fontSize.bodyMd};
	}
`;

export const Outer = styled.div`
	background-image: url('/images/large-oval.svg');
	background-position: right top -67px;
	background-repeat: no-repeat;
	background-size: 88%;
`;

export default Post;
